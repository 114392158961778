import Table from 'react-bootstrap/Table';
import Dog from '../types/Dog';
import { LinkContainer } from 'react-router-bootstrap'
import { useAppDispatch } from "../redux/hooks";
import Button from 'react-bootstrap/Button';
import { showModal } from '../reducers/CreateEditDogModalSlice';

interface DogsTableProps {
    dogs: { [id: string]: Dog },
    isEditable: boolean
}

interface DogRowProps {
    dog: Dog,
    isEditable: boolean
}

function DogRow(props: DogRowProps) {
    const dispatch = useAppDispatch();
    const dog = props.dog;
    return (
        <tr>
            {props.isEditable && (
                <td>
                    <Button onClick={() => dispatch(showModal({ isEditMode: true, dog }))}>
                        ✏️
                    </Button>
                </td>
            )}
            <LinkContainer to={`/dog/${dog.id}`}>
                <td>
                    {dog.callName}
                </td>
            </LinkContainer>
            <LinkContainer to={`/dog/${dog.id}`}>
                <td>
                    {
                        dog && dog.registeredNames &&
                        Object.entries(dog.registeredNames).map((entry) => {
                            return (<div key={`${entry[0]} ${entry[1].name}`}>{entry[0]}: {entry[1].name}</div>)
                        })
                    }
                </td>
            </LinkContainer>
            <LinkContainer to={`/dog/${dog.id}`}>
                <td>
                    {dog.breed}
                </td>
            </LinkContainer>
        </tr>
    )
}

export function DogsTable(props: DogsTableProps) {
    const dogs = props.dogs;
    return (<>
        <Table striped bordered hover >
            <thead>
                <tr>
                    {props.isEditable && (
                        <th>Edit</th>
                    )}
                    <th>Call Name</th>
                    <th>Registered Names</th>
                    <th>Breed</th>
                </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>
                {Object.keys(dogs).map((key) => (
                    <DogRow isEditable={props.isEditable} dog={dogs[key]} key={key} />
                ))}
            </tbody>
        </Table></>);
}