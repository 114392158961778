import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAppSelector, useAppDispatch } from '../redux/hooks';

import { CreateEditDogModalState, hideModal, updateDog } from '../reducers/CreateEditDogModalSlice';
import Dog, { RegisteredName } from '../types/Dog';

interface OnSubmitCallback {
    (dog: Dog): void;
}

interface CreateEditDogModalProps {
    organizations: string[],
    onSubmitCallback: OnSubmitCallback
};

export default function CreateEditDogModal(props: CreateEditDogModalProps) {
    const { show, dog, isEditMode } = useAppSelector((state) => state.createEditDogModal as CreateEditDogModalState);
    const dispatch = useAppDispatch();

    const onFormElementUpdate = (event: any) => {
        event.preventDefault();
        const args: string[] = event.target.id.split('.');
        let newDog: Dog = Object.assign({}, dog);
        if (event.target.id.includes('registeredName')) {
            const formData = new FormData(event.target.form);
            let seenOrgNames: string[] = [];
            let registeredNames: { [key: string]: RegisteredName } = {};
            for (let entry of formData.entries()) {
                if (entry[0] === 'registeredNameOrgName') {
                    seenOrgNames.push(entry[1] as string);
                }
                else if (entry[0].includes('registeredNames')) {
                    let innerArgs = entry[0].split('.');
                    if (!registeredNames[innerArgs[1]]) {
                        (registeredNames as any)[innerArgs[1]] = {};
                    }
                    (registeredNames as any)[innerArgs[1]][innerArgs[2]] = entry[1];
                }
            }
            let oddKeyOut: string = "";
            for (let key of seenOrgNames) {
                if (!Object.keys(registeredNames).includes(key)) {
                    oddKeyOut = key;
                    break;
                }
            }
            // Find the not matching org name if one exists
            let newRegisteredNames: { [key: string]: RegisteredName } = {};
            for (let key of Object.keys(registeredNames)) {
                if (!seenOrgNames.includes(key)) {
                    newRegisteredNames[oddKeyOut] = registeredNames[key];
                }
                else {
                    newRegisteredNames[key] = registeredNames[key];
                }
            }
            console.log(newRegisteredNames)

            newDog.registeredNames = newRegisteredNames;
        }
        else if (args.length === 1) {
            (newDog as any)[args[0]] = event.target.value
        }
        dispatch(updateDog(newDog));
    };

    const addBlankRegistration = () => {
        let newDog: Dog = Object.assign({}, dog);
        let newRegisteredNames: { [key: string]: RegisteredName } = {};
        for (let key of Object.keys(dog.registeredNames)) {
            newRegisteredNames[key] = dog.registeredNames[key];
        }

        let defaultOrg = "";
        for (let org of props.organizations) {
            if (!Object.keys(newRegisteredNames).includes(org)) {
                defaultOrg = org;
                break;
            }
        }

        newRegisteredNames[defaultOrg] = { name: "", id: "" };
        newDog.registeredNames = newRegisteredNames;
        dispatch(updateDog(newDog));
    }

    const removeRegistration = (event: any) => {
        const orgToDelete = event.target.id.split('.')[1];
        console.log(orgToDelete);
        let newDog: Dog = Object.assign({}, dog);
        let newRegisteredNames: { [key: string]: RegisteredName } = {};
        for (let key of Object.keys(dog.registeredNames)) {
            if (key !== orgToDelete) {
                newRegisteredNames[key] = dog.registeredNames[key];
            }
        }
        newDog.registeredNames = newRegisteredNames;
        dispatch(updateDog(newDog));
    }

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date: Date) => {
        return `${date.getFullYear()}-${padTo2Digits(date.getMonth() + 1)}-${padTo2Digits(date.getDate())}`;
    };

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={true}
            onHide={() => dispatch(hideModal())}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {isEditMode ? 'Edit Dog' : 'Add a Dog'}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(event) => {
                event.preventDefault();
                if (props.onSubmitCallback) {
                    props.onSubmitCallback(dog)
                }
                dispatch(hideModal());
            }} id="dogForm">
                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} name='callName' controlId='callName'>
                            <Form.Label>Call Name*</Form.Label>
                            <Form.Control value={dog.callName} onChange={onFormElementUpdate} name='callName' type="text" placeholder="Enter your dog's call name"></Form.Control>
                            <Form.Text className='text-muted'>This is the name you commonly use for your dog.</Form.Text>
                        </Form.Group>
                    </Row>

                    <Row>
                        <span className='form-label'>Official Registrations</span>
                    </Row>

                    {dog && dog.registeredNames &&
                        Object.keys(dog.registeredNames).map((key: string) => {

                            const orgName: string = key;
                            const registeredName: RegisteredName = dog.registeredNames[key];

                            return (

                                <Row key={`${orgName}`} className="mb-3" >

                                    <Form.Group style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} as={Col} xs={1} controlId={`registeredNames.${orgName}.delete`}>
                                        <Form.Label hidden>Registration</Form.Label>
                                        <Form.Control style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} name={`registeredNames.${orgName}.id`} value="❌" onClick={removeRegistration} type='button' placeholder='X' />
                                    </Form.Group >

                                    <Form.Group style={{ display: 'flex', alignItems: 'flex-end' }} as={Col} xs={2} controlId={orgName}>
                                        <Form.Label hidden>{orgName}</Form.Label>
                                        <Form.Select name='registeredNameOrgName' value={orgName} onChange={onFormElementUpdate} id={`registeredNameOrgName`}>
                                            {props.organizations.map((orgName) => (
                                                <option key={`${orgName}-option`}>
                                                    {orgName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group >

                                    <Form.Group as={Col} controlId={`registeredNames.${orgName}.id`}>
                                        <Form.Label hidden>Registration</Form.Label>
                                        <Form.Control name={`registeredNames.${orgName}.id`} value={registeredName.id} onChange={onFormElementUpdate} type='text' placeholder='Registration Number or ID' />
                                    </Form.Group >

                                    <Form.Group as={Col} controlId={`registeredNames.${orgName}.name`}>
                                        <Form.Label hidden>Registered Name</Form.Label>
                                        <Form.Control name={`registeredNames.${orgName}.name`} value={registeredName.name} onChange={onFormElementUpdate} type='text' placeholder='Registered Name' />
                                    </Form.Group >
                                </Row>
                            )
                        })
                    }

                    {
                        dog && dog.registeredNames && Object.keys(dog.registeredNames).length < props.organizations.length && (
                            <Row key='addRegistration' className="mb-3" >
                                <Button onClick={addBlankRegistration}>+ Add Registration</Button>
                            </Row>
                        )
                    }


                    <Form.Group className="mb-3" controlId='breed'>
                        <Form.Label>Breed*</Form.Label>
                        <Form.Control name="breed" onChange={onFormElementUpdate} value={dog && dog.breed} type="text" placeholder="Enter your dog's breed"></Form.Control>
                        <Form.Text className='text-muted'>This field is used for display on your dog's profile, you'll be able to enter a different value when registering for events.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId='birthday'>
                        <Form.Label>Birth Day*</Form.Label>
                        <Form.Control name="birthday" onChange={onFormElementUpdate} value={dog && dog.birthday && formatDate(new Date(dog.birthday))} type="date"></Form.Control>
                        <Form.Text className='text-muted'>Enter your dog's birthday.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId='height'>
                        <Form.Label>Height*</Form.Label>
                        <Form.Control name="height" onChange={onFormElementUpdate} value={dog && dog.height} step="0.01" type="number" placeholder="0.0"></Form.Control>
                        <Form.Text className='text-muted'>This is your dog's height in inches. You'll be able to enter a different value when registering for events.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId='weight'>
                        <Form.Label>Weight*</Form.Label>
                        <Form.Control name='weight' onChange={onFormElementUpdate} value={dog && dog.weight} step="0.01" type="number" placeholder="0.0"></Form.Control>
                        <Form.Text className='text-muted'>This is your dog's weight in pounds.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId='sire'>
                        <Form.Label>Sire</Form.Label>
                        <Form.Control name='sire' onChange={onFormElementUpdate} value={dog && (dog.sire || "")} type="text" placeholder="Sire"></Form.Control>
                        <Form.Text className='text-muted'>Search for your dog's sire.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId='dam'>
                        <Form.Label>Dam</Form.Label>
                        <Form.Control name='dam' onChange={onFormElementUpdate} value={dog && (dog.dam || "")} type="text" placeholder="Dam"></Form.Control>
                        <Form.Text className='text-muted'>Search for your dog's dam.</Form.Text>
                    </Form.Group>

                    <span className='text-muted'>*This field is required in order to register your dog.</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => dispatch(hideModal())} variant='secondary'>Close</Button>
                    <Button variant='primary' type='submit'>Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal >
    );
}