import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from "react-router";

export default function DogProfile() {

    const { dogId } = useParams();

    return (
        <Container>
            <Row>
                <Col>
                    <h1>
                        {dogId}
                    </h1>
                </Col>
            </Row>
        </Container>);
}