import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Dog from '../types/Dog';

export interface CreateEditDogModalState {
    show: boolean,
    isEditMode: boolean,
    dog: Dog
}

export interface ShowModalParams {
    isEditMode: boolean,
    dog?: Dog
}

export const createEditDogModalSlice = createSlice({
    name: 'createEditDogModal',
    initialState: {
        show: false,
        isEditMode: false,
        dog: {}
    },
    reducers: {
        showModal(state, action: PayloadAction<ShowModalParams>) {
            state.show = true;
            state.isEditMode = action.payload.isEditMode;
            state.dog = action.payload.dog || {};
        },
        hideModal(state) {
            state.show = false;
        },
        updateDog(state, action: PayloadAction<Dog>) {
            state.dog = Object.assign({}, state.dog, action.payload)
        }
    }
});


export const { showModal, hideModal, updateDog } = createEditDogModalSlice.actions;
export default createEditDogModalSlice.reducer;