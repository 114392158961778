import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from "react-router";
import { useAppSelector } from "../redux/hooks";
import { ProfileMap } from "../reducers/ProfilesSlice";
import { AuthState } from "../reducers/AuthSlice";

export default function Profile() {

    const { userId } = useParams();
    const { activeUserId } = useAppSelector((state) => state.auth as AuthState);
    const resolvedUserId = userId === 'me' ? activeUserId : userId;
    const { profile } = useAppSelector((state) => (state.profiles as ProfileMap)[resolvedUserId || '']);

    return (
        <Container>
            <Row>
                <Col>
                    <h1>
                        {profile.displayName}
                    </h1>
                </Col>
            </Row>
            {profile.email &&
                <Row>
                    <Col>
                        <h2>
                            {profile.email}
                        </h2>
                    </Col>
                </Row>
            }
        </Container >
    );
}