import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store } from './redux/store'

import 'bootstrap/dist/css/bootstrap.min.css';

import Index from './routes/Index';
import Club from './routes/Club';
import Profile from './routes/Profile';
import Error from './routes/Error';
import DogProfile from './routes/DogProfile';
import EventPage from './routes/Event';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <Index />
      },
      {
        path: '/club/:clubId',
        element: <Club />
      },
      {
        path: '/profile/:userId',
        element: <Profile />
      },
      {
        path: '/dog/:dogId',
        element: <DogProfile />
      },
      {
        path: '/event/:eventId',
        element: <EventPage />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
