import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import Event from '../types/Event'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Container from "react-bootstrap/Container"

export interface CalendarProps {
    events: Event[]
}

export function Calendar(props: CalendarProps) {
    const navigate = useNavigate();
    return (
        <Container>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
                themeSystem='bootstrap5'
                footerToolbar={{
                    center: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                views={{
                    "timeGrid": {
                        titleFormat: { month: 'short', day: 'numeric' }
                    }
                }}
                initialView='dayGridMonth'
                weekends={true}
                events={props.events}
                height="auto"
                eventClick={(eventInfo: any) => {
                    console.log(eventInfo);
                    navigate(`/event/${eventInfo.event.id}`)
                }}
            />
        </Container>
    )
}