import { configureStore } from '@reduxjs/toolkit'
import AuthReducer, { authLocalStorageMiddleware } from '../reducers/AuthSlice';
import ProfilesReducer from '../reducers/ProfilesSlice';
import CreateEditDogModalReducer from '../reducers/CreateEditDogModalSlice';
import EventsReducer from '../reducers/EventsSlice';
import { RootApiService } from '../services/RootApiService';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        profiles: ProfilesReducer,
        createEditDogModal: CreateEditDogModalReducer,
        events: EventsReducer,
        [RootApiService.reducerPath]: RootApiService.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .prepend(authLocalStorageMiddleware)
            .concat(RootApiService.middleware)
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;