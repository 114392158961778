import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Dog from '../types/Dog';
import UserProfile from '../types/UserProfile';

export interface ProfileState {
    primaryHandledDogs: { [id: string]: Dog },
    otherHandledDogs: { [id: string]: Dog },
    upcomingEvents: string[],
    pastEvents: string[],
    profile: UserProfile
}

export interface ProfileMap {
    [userId: string]: ProfileState
}

export interface UpdateDogParams {
    userId: string,
    dog: Dog
}

const initialState: ProfileMap = {
    'e9c015f8-662c-4c03-b1d1-5e5f38316731': {
        primaryHandledDogs: {
            "fakeId": {
                id: "fakeId",
                primaryHandlerId: "e9c015f8-662c-4c03-b1d1-5e5f38316731",
                callName: 'Major',
                registeredNames: { 'AKC': { name: 'The Major Arcana', id: '12351233' }, 'NASDA': { name: 'The Major Arcana', id: '12351233' } },
                breed: 'Mixed',
                height: 24.5,
                weight: 95.5,
                sire: null,
                dam: null,
                birthday: (new Date("December 10, 2019")).toLocaleDateString(),
                sex: 'male'
            },
            "fakeId1": {
                id: "fakeId1",
                primaryHandlerId: "e9c015f8-662c-4c03-b1d1-5e5f38316731",
                callName: 'Minor',
                registeredNames: { 'AKC': { name: 'The Major Arcana', id: '12351233' }, 'NASDA': { name: 'The Major Arcana', id: '12351233' } },
                breed: 'Mixed',
                height: 24.5,
                weight: 95.5,
                sire: null,
                dam: null,
                birthday: (new Date("December 9, 2019")).toLocaleDateString(),
                sex: 'male'
            }
        },
        otherHandledDogs: {
            "fakeId2": {
                id: "fakeId2",
                primaryHandlerId: "somebody else",
                callName: 'Lucy',
                registeredNames: { 'AKC': { name: 'The Major Arcana', id: '12351233' }, 'NASDA': { name: 'The Major Arcana', id: '12351233' } },
                breed: 'Mixed',
                height: 24.5,
                weight: 95.5,
                sire: null,
                dam: null,
                birthday: (new Date("December 10, 2019")).toLocaleDateString(),
                sex: 'female'
            },
            "fakeId3": {
                id: "fakeId3",
                primaryHandlerId: "somebody else",
                callName: 'Diamond',
                registeredNames: { 'AKC': { name: 'The Major Arcana', id: '12351233' }, 'NASDA': { name: 'The Major Arcana', id: '12351233' } },
                breed: 'Mixed',
                height: 24.5,
                weight: 95.5,
                sire: null,
                dam: null,
                birthday: (new Date("December 9, 2019")).toLocaleDateString(),
                sex: 'female'
            }
        },
        pastEvents: ["fakeEventId", "fakeEventId1"],
        upcomingEvents: ["fakeEventId2", "fakeEventId3"],
        profile: {
            displayName: "Morgan Hoke",
            email: "morgan.hoke@gmail.com",
            imageUri: "/logo512.png"
        }
    }
} as ProfileMap;

export const profilesSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        addOrUpdatePrimaryHandledDog(state, action: PayloadAction<UpdateDogParams>) {
            state[action.payload.userId].primaryHandledDogs[action.payload.dog.id] = action.payload.dog;
        }
    }
});


export const { addOrUpdatePrimaryHandledDog } = profilesSlice.actions;
export default profilesSlice.reducer;