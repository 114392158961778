import { AccountButton } from "./AccountButton";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container'
import './HeaderOverrides.css'
import { LinkContainer } from 'react-router-bootstrap'



export default function Header() {

    return (
        <Navbar bg="light" expand="lg" className="mb-3" sticky="top">
            <Container >

                <Navbar.Brand className="logo-setup"><Nav.Link href="/" className="logo-title">DoggieScore</Nav.Link> <div className="logo-content"><AccountButton /></div></Navbar.Brand>

                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <AccountButton />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">

                            <LinkContainer to="/clubs">
                                <Nav.Link as="a">Clubs</Nav.Link>
                            </LinkContainer>


                            <LinkContainer to="/events">
                                <Nav.Link as="a">Events</Nav.Link>
                            </LinkContainer>

                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar >
    );
}
