// import { useGetHelloQuery, usePostNewHelloMutation } from '../services/HelloService';
import { AuthState } from "../reducers/AuthSlice"
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Calendar } from '../components/Calendar';
import { ProfileMap } from "../reducers/ProfilesSlice";
import CreateEditDogModal from "../components/CreateEditDogModal";
import { DogsTable } from "../components/DogsTable";
import { EventsTable } from "../components/EventsTable";
import { showModal } from "../reducers/CreateEditDogModalSlice";
import { EventsState } from "../reducers/EventsSlice";
import { addOrUpdatePrimaryHandledDog } from '../reducers/ProfilesSlice'
import { useState } from "react";


export default function Index() {


    // const { data, isLoading } = useGetHelloQuery({ id: "1c1a0aa1-5e82-4e2b-94b0-32c14bda51bd", name: "Morgan" });
    // const [createHello, { data: postData, isLoading: postIsLoading }] = usePostNewHelloMutation();

    const { loggedIn, activeUserId } = useAppSelector((state) => state.auth as AuthState);
    const { primaryHandledDogs, otherHandledDogs, upcomingEvents: upcomingEventsIds, pastEvents: pastEventsIds } = useAppSelector((state) => (state.profiles as ProfileMap)[activeUserId || 'me']) || { primaryHandledDogs: [], otherHandledDogs: [], upcomingEvents: [], pastEvents: [] };

    const upcomingEvents = useAppSelector((state) => upcomingEventsIds.map(id => (state.events as EventsState)[id]));
    const pastEvents = useAppSelector((state) => pastEventsIds.map(id => (state.events as EventsState)[id]));

    const dispatch = useAppDispatch();

    const [tabKey, setTabKey] = useState('calendar');

    return (
        <>
            {!loggedIn && (
                <Container>
                    <Row>
                        <Col>
                            <h2>
                                Welcome to DoggieScore, the place for your dogs' scores!
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Please log in to access your individual dashboard. You can also use the links at the top of the page to find nearby clubs or events.
                            </p>
                        </Col>
                    </Row>
                </Container>
            )}
            {loggedIn && (
                <Container>
                    <CreateEditDogModal onSubmitCallback={(dog) => {
                        dispatch(addOrUpdatePrimaryHandledDog({ userId: activeUserId, dog }));
                    }} organizations={['AKC', 'BHA', 'NASDA']} />
                    <Tabs
                        defaultActiveKey="calendar"
                        className="mb-3"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k || "calendar")}
                    >
                        <Tab eventKey="calendar" title="Calendar">
                            {tabKey === "calendar" && <Calendar events={[...upcomingEvents, ...pastEvents, ...upcomingEvents.map(event => ({ ...event, start: event.registrationOpens, end: event.registrationCloses, title: `${event.title} registration open` }))]} />}
                        </Tab>
                        <Tab eventKey="inbox" title="Inbox">

                        </Tab>
                        <Tab eventKey="upcomingEvents" title="Upcoming Events">
                            <EventsTable events={upcomingEvents} />
                        </Tab>
                        <Tab eventKey="pastEvents" title="Past Events">
                            <EventsTable events={pastEvents} />
                        </Tab>
                        <Tab eventKey="clubs" title="My Clubs">

                        </Tab>
                        <Tab eventKey="dogs" title="My Dogs">
                            <Container>
                                <Row>
                                    <Col>
                                        <h3>
                                            My Dogs
                                        </h3>
                                        {primaryHandledDogs && Object.keys(primaryHandledDogs).length > 0 && (<DogsTable isEditable={true} dogs={primaryHandledDogs} />)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Container>
                                        <Button onClick={() => dispatch(showModal({ isEditMode: false, dog: { birthday: new Date("January 1, 2020").toISOString(), primaryHandlerId: activeUserId, breed: "", callName: "", dam: "", height: 20, id: "", registeredNames: {}, sex: "", sire: "", weight: 20, picture: "" } }))}>Add a Dog</Button>
                                    </Container>
                                </Row>
                            </Container>
                            {otherHandledDogs && Object.keys(otherHandledDogs).length > 0 && (<>
                                <Row><br /></Row>
                                <Container>
                                    <Row>
                                        <Col>
                                            <h3>
                                                Other Dogs I've Handled
                                            </h3>
                                            <DogsTable isEditable={false} dogs={otherHandledDogs} />
                                        </Col>
                                    </Row>
                                </Container>
                            </>)}

                        </Tab>
                    </Tabs>
                </Container>
            )}
        </>
    );
}