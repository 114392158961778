import Event from "../types/Event";
import Table from 'react-bootstrap/Table';
import { LinkContainer } from 'react-router-bootstrap'

interface EventsTableProps {
    events: Event[]
}

interface EventRowProps {
    event: Event
}

function EventRow(props: EventRowProps) {
    const event = props.event;
    return (<>
        <tr>
            <LinkContainer to={`/event/${event.id}`}>
                <td>
                    {event.title}
                </td>
            </LinkContainer>
            <LinkContainer to={`/club/${event.clubId}`}>
                <td>
                    {event.clubName}
                </td>
            </LinkContainer>
            <td>
                {event.start.toLocaleDateString()}
            </td>
        </tr>
    </>
    )
}

export function EventsTable(props: EventsTableProps) {
    const events = props.events;
    return (<>
        <Table striped bordered hover >
            <thead>
                <tr>
                    <th>Event Name</th>
                    <th>Club Name</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>
                {events.map((event) => (
                    <EventRow event={event} key={event.id} />
                ))}
            </tbody>
        </Table></>)
}