import { useParams } from "react-router";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAppSelector } from "../redux/hooks";
import { EventsState } from "../reducers/EventsSlice";
import Button from "react-bootstrap/Button";

export default function EventPage() {

    const { eventId } = useParams();
    const event = useAppSelector((state) => (state.events as EventsState)[eventId || '']);

    const registrationOpensInFuture = event?.registrationOpens ? event.registrationOpens > new Date() : false;
    const registrationClosesInFuture = event?.registrationCloses ? event.registrationCloses > new Date() : false;

    const registrationOpenNow = !registrationOpensInFuture && registrationClosesInFuture;

    let slotsAvailable = false;
    event.trials.forEach(trial => {
        if (trial.maxEntries > trial.currentEntries) {
            slotsAvailable = true;
        }
    });

    return (
        <Container>
            {event && (
                <>
                    <Row>
                        <Col>
                            <h1>
                                {event.title}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>
                                Presented by {event.clubName}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {event.address}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>
                                Registration {registrationOpenNow ? 'Is Open' : (registrationOpensInFuture ? 'Will be Open' : 'Is Closed')}
                            </h4>
                            {registrationOpenNow && (
                                <p>
                                    {event.registrationOpens.toLocaleString()} - {event.registrationCloses.toLocaleString()}
                                </p>)
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>
                                The event {event.start > new Date() ? 'will be held' : 'was held'}
                            </h4>
                            <p>
                                {event.start.toLocaleString()} - {event.end.toLocaleString()}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {event.description}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>
                                The following trials will be offered
                            </h3>
                        </Col>
                    </Row>
                    {event.trials && event.trials.map(trial => (
                        <>
                            <Row>
                                <Col>
                                    <h4>
                                        {trial.title}
                                    </h4>
                                    <p>
                                        {trial.description}
                                    </p>
                                </Col>
                            </Row>
                        </>
                    ))}
                    {registrationOpenNow && slotsAvailable && (
                        <Row>
                            <Col>
                                <Button>
                                    Register a dog
                                </Button>
                            </Col>
                        </Row>
                    )}

                </>
            )}
        </Container>
    );
}