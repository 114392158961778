import { createSlice } from '@reduxjs/toolkit';
import Event from '../types/Event';

export interface EventsState {
    [id: string]: Event
}

const initialState: EventsState = {
    'fakeEventId': {
        address: "1234 Nowhere Street, Some Town USA",
        clubName: "The first club",
        start: new Date(new Date().getTime() - (5 * 24 * 60 * 60 * 1000)),
        end: new Date(new Date().getTime() - (4 * 24 * 60 * 60 * 1000)),
        registrationOpens: new Date(new Date().getTime() - (10 * 24 * 60 * 60 * 1000)),
        registrationCloses: new Date(new Date().getTime() - (5 * 24 * 60 * 60 * 1000)),
        title: "Really awesome Trial",
        id: "fakeEventId",
        clubId: "fakeClubId",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        trials: [{
            currentEntries: 0,
            maxEntries: 100,
            description: 'A master barn hunt trial',
            id: 'fakeTrialId',
            organization: "BHA",
            title: "Masters Trial 1"
        },
        {
            currentEntries: 0,
            maxEntries: 100,
            description: 'A master barn hunt trial 2',
            id: 'fakeTrialId1',
            organization: "BHA",
            title: "Masters Trial 2"
        }]
    },
    "fakeEventId1": {
        address: "1234 Nowhere Street, Some Town USA",
        clubName: "The first club",
        registrationOpens: new Date(new Date().getTime() - (10 * 24 * 60 * 60 * 1000)),
        registrationCloses: new Date(new Date().getTime() - (5 * 24 * 60 * 60 * 1000)),
        start: new Date(new Date().getTime() - (5 * 24 * 60 * 60 * 1000)),
        end: new Date(new Date().getTime() - (4 * 24 * 60 * 60 * 1000)),
        title: "Really awesome Trial 2",
        id: "fakeEventId1",
        clubId: "fakeClubId",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        trials: []
    },
    "fakeEventId2": {
        address: "1234 Nowhere Street, Some Town USA",
        clubName: "The first club",
        registrationOpens: new Date(new Date().getTime() - (5 * 24 * 60 * 60 * 1000)),
        registrationCloses: new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
        start: new Date(),
        end: new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)),
        title: "Really awesome Trial",
        id: "fakeEventId2",
        clubId: "fakeClubId",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        trials: []
    },
    "fakeEventId3": {
        address: "1234 Nowhere Street, Some Town USA",
        clubName: "The first club",
        registrationOpens: new Date(new Date().getTime() - (4 * 24 * 60 * 60 * 1000)),
        registrationCloses: new Date(new Date().getTime() + (60 * 60 * 1000)),
        start: new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)),
        end: new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)),
        title: "Really awesome Trial 2",
        id: "fakeEventId3",
        clubId: "fakeClubId",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        trials: [{
            currentEntries: 0,
            maxEntries: 100,
            description: 'A master barn hunt trial3',
            id: 'fakeTrialId2',
            organization: "BHA",
            title: "Masters Trial 3"
        },
        {
            currentEntries: 0,
            maxEntries: 100,
            description: 'A master barn hunt trial 4',
            id: 'fakeTrialId3',
            organization: "BHA",
            title: "Masters Trial 4"
        }]
    }
}

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {

    }
});

export default eventsSlice.reducer;