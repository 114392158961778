import { createSlice, createListenerMiddleware } from '@reduxjs/toolkit'
import { AuthApiService } from '../services/AuthService'
import UserProfile from '../types/UserProfile';

export interface AuthState {
    loggedIn: boolean,
    activeUserId: string,
    googleAuthCode: string,
    appAuthToken: string,
    profile: UserProfile
}

function AuthStateEqual(self: AuthState, other: AuthState): boolean {
    return self.appAuthToken === other.appAuthToken &&
        self.googleAuthCode === other.googleAuthCode &&
        self.activeUserId === other.activeUserId &&
        self.loggedIn === other.loggedIn &&
        self.profile.displayName === other.profile.displayName &&
        self.profile.email === other.profile.email
}

const initialState: AuthState = {
    loggedIn: JSON.parse(window.localStorage.getItem('AppAuth') || "{}")?.loggedIn,
    googleAuthCode: decodeURIComponent((new URL(window.location.href)).searchParams.get('code') || ""),
    appAuthToken: JSON.parse(window.localStorage.getItem('AppAuth') || "{}")?.appAuthToken,
    activeUserId: JSON.parse(window.localStorage.getItem('AppAuth') || "{}")?.activeUserId,
    profile: {
        displayName: JSON.parse(window.localStorage.getItem('AppAuth') || "{}")?.profile?.displayName,
        email: JSON.parse(window.localStorage.getItem('AppAuth') || "{}")?.profile?.email
    }
} as AuthState;

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut(state) {
            state.appAuthToken = "";
            state.googleAuthCode = "";
            state.activeUserId = "";
            state.profile.email = "";
            state.profile.displayName = "";
            state.loggedIn = false;

        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            AuthApiService.endpoints.postLogIn.matchFulfilled,
            (state, { payload }) => {
                state.appAuthToken = payload.token;
                state.activeUserId = payload.id;
                state.profile.displayName = payload.displayName;
                state.profile.email = payload.email;
                state.loggedIn = true;
            }
        )
    }
});

const serializeAuthToLocalStorageMiddleware = createListenerMiddleware();

// Serialize state to local storage for persistence
serializeAuthToLocalStorageMiddleware.startListening({
    predicate: (action, currentState: any, previousState: any) => {
        return !AuthStateEqual(currentState.auth as AuthState, previousState.auth as AuthState);
    },
    effect: (action, localStorageApi) => {
        window.localStorage.setItem('AppAuth', JSON.stringify((localStorageApi.getState() as any).auth));
    }
})



export const { logOut } = authSlice.actions;
export default authSlice.reducer;
export const authLocalStorageMiddleware = serializeAuthToLocalStorageMiddleware.middleware;